// eslint-disable-next-line no-unused-vars
import { readingTime as readingTimeHelper } from "@tryghost/helpers"
import { Link } from "gatsby"
import Img from "gatsby-image"
import React from "react"

/** @jsx jsx */
import { jsx } from "theme-ui"

const MorePostsCard = ({ post }) => {
  if (!post) {
    return null
  }
  const readingTime = readingTimeHelper(post)
  const lang = post.tags.find(tag => ["en", "fr"].includes(tag.slug)).slug
  const url =
    lang === "fr" ? `/${lang}/blog/${post.slug}/` : `/blog/${post.slug}/`
  return (
   
    <Link sx={{ textDecoration: 'none', color: "inherit" }} to={url}>
      <div
        sx={{
          display: `flex`,
          flexDirection: `column`,
          minWidth: `16rem`,
          marginRight: `0.5rem`,
        }}
      >
        <Img
          fluid={post.featureImageSharp.childImageSharp.fluid}
          alt={post.title}
          sx={{ height: `12rem`, borderRadius: `20px`, marginBottom: `1rem` }}
        />
        <div sx={{ display: `flex`, flexDirection: `column` }}>
          <h4 sx={{ width: `95%` }}>{post.title}</h4>
          <div sx={{ display: `flex`, justifyContent: `space-between` }}>
            <h4 sx={{ mb: `0.5rem` }}>
              {new Date(post.published_at).toDateString()}
            </h4>
            <p>{readingTime}</p>
          </div>
          <p>{post.authors[0].name}</p>
        </div>
      </div>
      </Link>
     
  )
}

export default MorePostsCard

// id: "Ghost__Post__5f90588ce636ca0001c7f2b2"
// published_at: "We.57.2020"
// reading_time: null
// slug: "amazon-programme-born-to-run"
// title: "Lancement de produit sur Amazon : Utilisez le programme “Born to Run”"
// url: "https://blog.seelk.io/amazon-programme-born-to-run/"
