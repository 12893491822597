// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { jsx } from "theme-ui"
import MorePostsCard from "./more-post-card"

const MorePosts = ({ posts, lang }) => {
  const filteredPostsByLanguage = posts.filter(post =>
    post.node.tags.find(tag => tag.slug.includes(lang))
  )

  return (
    <div sx={{ marginTop: `7rem`, width: `70%`, margin: `auto` }}>
      <h4>More from Seelk</h4>
      <div
        sx={{
          display: `flex`,
          justifyContent: `space-between`,
          flexDirection: [`column`, `column`, `row`],
        }}
      >
        {filteredPostsByLanguage.map((post, i) => (
          <MorePostsCard key={i} post={post.node} />
        ))}
      </div>
    </div>
  )
}

export default MorePosts
