// eslint-disable-next-line no-unused-vars
import React from "react"
/** @jsx jsx */
import { Divider, jsx } from "theme-ui"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Layout from "../components/layout/layout"
import MetaDataQuery from "../components/common/meta/MetaData"
import Container from "../components/layout/container"
import { colors } from "../utils/theme/colors"
import Img from "gatsby-image"
import MorePosts from "../components/blog/morePosts"

const Post = ({ data, location, pageContext }) => {
  const { allContentfulFooter } = data
  const post = data.ghostPost
  const langKey = pageContext.langKey

  return (
    <>
      <Helmet>
        <meta name="format-detection" content="telephone=no" />
      </Helmet>
      <MetaDataQuery data={data} location={location} type="article" />
      <Layout allContentfulFooter={allContentfulFooter}>
        <Container>
          <article
            className="content"
            sx={{
              "& .css-17xgyl3-Post": {
                "& .css-70v7ad-Post": {
                  h2: {
                    color: colors.code.secondary,
                    fontSize: [`1.125rem`, `1.125rem`, `1.375rem`, `1.375rem`],
                  },
                },
              },
            }}
          >
            {post.feature_image ? (
              <figure className="post-feature-image">
                <h1
                  sx={{
                    width: [`100%`, `100%`, `60%`, `60%`],
                    margin: `auto`,
                    textAlign: `center`,
                    color: colors.code.secondary,
                    paddingTop: `10rem`,
                    fontSize: [`1.4rem`, `2.5rem`, `2.5rem`, `2.5rem`],
                  }}
                  className="content-title"
                >
                  {post.title}
                </h1>
                <div
                  sx={{
                    display: `flex`,
                    justifyContent: `center`,
                    paddingTop: `3rem`,
                  }}
                >
                  <Img
                    fluid={post.featureImageSharp.childImageSharp.fluid}
                    sx={{ width: [`100%`, `100%`, `70%`, `70%`] }}
                    alt={post.title}
                  />
                </div>
              </figure>
            ) : null}
            <div
              sx={{
                display: `flex`,
                width: [`100%`, `100%`, `70%`, `70%`],
                justifyContent: `center`,
                margin: `auto`,
              }}
            >
              <section>
                <section
                  sx={{
                    "& .h2": {
                      color: `red`,
                    },
                  }}
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
                <Divider sx={{ mt: `5rem`, color: `#100720`, opacity: 0.1 }} />
              </section>
            </div>
            <div
              sx={{
                display: `flex`,
                width: [`100%`, `100%`, `70%`, `70%`],
                margin: `auto`,
                my: `5rem`,
                alignItems: `center`,
              }}
            >
              {post.authors[0].profile_image && (
                <img
                  src={post.authors[0].profile_image}
                  alt={post.title}
                  sx={{
                    borderRadius: `50%`,
                    objectFit: `cover`,
                    width: `6.25rem`,
                    height: `6.25rem`,
                    marginRight: `1.875rem`,
                  }}
                />
              )}
              <div
                sx={{
                  display: `flex`,
                  flexDirection: `column`,
                }}
              >
                <h4
                  sx={{
                    mr: `0.5rem`,
                    mb: `0.5rem`,
                  }}
                >
                  {post.authors[0].name}
                </h4>
                <p sx={{ maxWidth: `27.5rem` }}>{post.authors[0].bio}</p>
              </div>
            </div>
            <MorePosts posts={data.allGhostPost.edges} lang={langKey} />
          </article>
        </Container>
      </Layout>
    </>
  )
}

Post.propTypes = {
  data: PropTypes.shape({
    ghostPost: PropTypes.shape({
      codeinjection_styles: PropTypes.object,
      title: PropTypes.string.isRequired,
      html: PropTypes.string.isRequired,
      feature_image: PropTypes.string,
    }).isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
  query($id: String!, $langKey: String) {
    ghostPost(id: { eq: $id }) {
      ...GhostPostFields
      featureImageSharp {
        childImageSharp {
          fluid(maxWidth: 1400, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    allGhostPost(
      filter: { tags: { elemMatch: { slug: { eq: "most-popular" } } } }
      sort: { order: DESC, fields: published_at }
    ) {
      edges {
        node {
          ...GhostPostFields
          tags {
            slug
          }
          featureImageSharp {
            childImageSharp {
              fluid(maxWidth: 1400, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    allContentfulFooter(
      filter: { node_locale: { eq: $langKey }, agencyServices: { ne: null } }
    ) {
      edges {
        node {
          node_locale
          seelkOffices {
            officeCity
            officeAddress
            officeTelephoneNumber
          }
          saasForAmazon
          agencyServices
          aboutSeelk
          copyrights
          contact
          connect
        }
      }
    }
  }
`
